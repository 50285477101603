@font-face {
  font-family: "Lato-Black";
  src: url("./assets/fonts/Lato-Black.svg") format("svg"), url("./assets/fonts/Lato-Black.ttf") format("ttf"),
    url("./assets/fonts/Lato-Black.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Bold";
  src: url("./assets/fonts/Lato-Bold.svg#Lato-Bold") format("svg"),
    url("./assets/fonts/Lato-Bold.ttf#Lato-Bold") format("ttf"), url("./assets/fonts/Lato-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Light";
  src: url("./assets/fonts/Lato-Light.svg#Lato-Light") format("svg"),
    url("./assets/fonts/Lato-Light.ttf#Lato-Light") format("ttf"), url("./assets/fonts/Lato-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("./assets/fonts/Lato-Regular.svg#Lato-Regular") format("svg"),
    url("./assets/fonts/Lato-Regular.ttf#Lato-Regular") format("svg"),
    url("./assets/fonts/Lato-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Hairline";
  src: url("./assets/fonts/Lato-Hairline.svg#Lato-Hairline") format("svg"),
    url("./assets/fonts/Lato-Thin.ttf") format("ttf"), url("./assets/fonts/Lato-Hairline.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Lato-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html,
body,
#root,
.App {
  height: 100%;
}

:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

/**
auth page styles 
**/

.authwrapper {
  min-height: 100%;
  position: relative;
  background-color: #2c2c2c;
  /* overflow: hidden; */
  padding-bottom: 40px;
}

.authwrapper.prelogin {
  height: 100%;
  overflow: hidden;
}

.auth-container {
  height: 75%;
  position: relative;
  margin: 0 auto;
  padding: 0 15px;
  max-width: 80vw;
}

.corner-art {
  position: absolute;
  width: 35%;
  height: 57%;
  background-size: cover;
  background-repeat: no-repeat;
  /* right: -13%; */
  background-position: 0 0;
  /* top: -7%; */
  z-index: 0;
  overflow: hidden;
}

.corner-art.left {
  left: 0;
  bottom: 0;
}

.corner-art.right {
  right: 0;
  top: 0;
}

.corner-art.left > img {
  width: 100%;
  height: 100%;
  position: relative;
  left: -13%;
  bottom: -7%;
}

.corner-art.right > img {
  width: 100%;
  height: 100%;
  position: relative;
  right: -13%;
  top: -7%;
}

/* .authwrapper:before {
  content: '';
  background-image: url('./assets/images/right-top-shade.png');
  position: absolute;
  width: 35%;
  height: 57%;
  background-size: cover;
  background-repeat: no-repeat;
  right: -13%;
  background-position: 0 0;
  top: -7%;
  z-index: 0;
}

.authwrapper:after {
  content: '';
  background-image: url('./assets/images/left-bottom-shade.png');
  position: absolute;
  width: 35%;
  height: 57%;
  background-size: cover;
  background-repeat: no-repeat;
  left: -13%;
  background-position: 0 0;
  bottom: -7%;
  z-index: 0;
} */

.authHeader {
  position: relative;
  padding: 20px 0;
}

.authHeader > a.authlogo {
  display: inline-block;
}

.authHeader > a.authlogo > img {
  width: 110px;
}

.auth-col {
  float: left;
  position: relative;
  height: 100%;
}

.auth-col.right {
  width: 38%;
}

.auth-col.left {
  width: 62%;
}

img.collage {
  position: absolute;
}

.auth-collage {
  position: relative;
  height: 100%;
}

/* .collage-1 {
  z-index: 6;
  width: 28%;
  /* left: 20%;
  top: 11%; 
 animation-name: rotate1;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 20s; 
} */

@keyframes rotate1 {
  0% {
    /* transform: translate3d(80%, 24%, 0); */
    z-index: 2;
    transform: translate3d(-10%, 19%, 0);
  }
  20% {
    z-index: 1;
    /* transform: translate3d(-10%, 19%, 0); */
    transform: translate3d(24%, 2%, 0);
  }
  40% {
    z-index: 3;
    /* transform: translate3d(24%, 2%, 0); */
    transform: translate3d(148%, 2%, 0);
  }
  60% {
    z-index: 4;
    /* transform: translate3d(148%, 2%, 0); */
    transform: translate3d(201%, 12%, 0);
  }
  80% {
    z-index: 5;
    /* transform: translate3d(201%, 12%, 0); */
    transform: translate3d(166%, 20%, 0);
  }
  100% {
    z-index: 6;
    /* transform: translate3d(166%, 20%, 0); */
    transform: translate3d(80%, 24%, 0);
  }
}

@keyframes rotate2 {
  0% {
    z-index: 6;
    transform: translate3d(80%, 24%, 0);
  }
  20% {
    z-index: 2;
    transform: translate3d(-10%, 19%, 0);
  }
  40% {
    z-index: 1;
    transform: translate3d(24%, 2%, 0);
  }
  60% {
    z-index: 3;
    transform: translate3d(148%, 2%, 0);
  }
  80% {
    z-index: 4;
    transform: translate3d(201%, 12%, 0);
  }
  100% {
    z-index: 5;
    transform: translate3d(166%, 20%, 0);
  }
}

@keyframes rotate3 {
  0% {
    z-index: 5;
    transform: translate3d(166%, 20%, 0);
  }
  20% {
    z-index: 6;
    transform: translate3d(80%, 24%, 0);
  }
  40% {
    z-index: 1;
    transform: translate3d(-10%, 19%, 0);
  }
  60% {
    z-index: 2;
    transform: translate3d(24%, 2%, 0);
  }
  80% {
    z-index: 3;
    transform: translate3d(148%, 2%, 0);
  }
  100% {
    z-index: 4;
    transform: translate3d(201%, 12%, 0);
  }
}

@keyframes rotate4 {
  0% {
    z-index: 4;
    transform: translate3d(201%, 12%, 0);
  }
  20% {
    z-index: 5;
    transform: translate3d(166%, 20%, 0);
  }
  40% {
    z-index: 6;
    transform: translate3d(80%, 24%, 0);
  }
  60% {
    z-index: 2;
    transform: translate3d(-10%, 19%, 0);
  }
  80% {
    z-index: 1;
    transform: translate3d(24%, 2%, 0);
  }
  100% {
    z-index: 3;
    transform: translate3d(148%, 2%, 0);
  }
}

@keyframes rotate5 {
  0% {
    z-index: 1;
    transform: translate3d(24%, 2%, 0);
  }
  20% {
    z-index: 3;
    transform: translate3d(148%, 2%, 0);
  }
  40% {
    z-index: 4;
    transform: translate3d(201%, 12%, 0);
  }
  60% {
    z-index: 5;
    transform: translate3d(166%, 20%, 0);
  }
  80% {
    z-index: 6;
    transform: translate3d(80%, 24%, 0);
  }
  100% {
    z-index: 2;
    transform: translate3d(-10%, 19%, 0);
  }
}

@keyframes rotate6 {
  0% {
    z-index: 3;
    transform: translate3d(148%, 2%, 0);
  }
  20% {
    z-index: 4;
    transform: translate3d(201%, 12%, 0);
  }
  40% {
    z-index: 5;
    transform: translate3d(166%, 20%, 0);
  }
  60% {
    z-index: 6;
    transform: translate3d(80%, 24%, 0);
  }
  80% {
    z-index: 2;
    transform: translate3d(-10%, 19%, 0);
  }
  100% {
    z-index: 1;
    transform: translate3d(24%, 2%, 0);
  }
}

.collage-1 {
  z-index: 6;
  width: 171px;
  transform: translate3d(80%, 24%, 0);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

.collage-2 {
  width: 171px;
  z-index: 5;
  transform: translate3d(166%, 20%, 0);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

.collage-3 {
  width: 171px;
  z-index: 4;
  transform: translate3d(201%, 12%, 0);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

.collage-4 {
  width: 171px;
  z-index: 3;
  transform: translate3d(148%, 2%, 0);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

.collage-5 {
  transform: translate3d(-10%, 19%, 0);
  width: 171px;
  z-index: 2;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

.collage-6 {
  width: 171px;
  z-index: 1;
  transform: translate3d(24%, 2%, 0);
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  animation-duration: 35s;
}

/* .collage-6 {
  width: 171px;
  top: 1%;
  left: 7%;
  z-index: 1;
} */

.auth-col-wrapper {
  height: 100%;
}

.authLoginBoxWrap {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.21);
  padding: 20px 20px 38px 20px;
}

.authLoginBoxWrap.signupbox {
  position: relative;
  top: -8%;
}

.authLoginBoxWrap.loginbox {
  padding-top: 26px;
}

.authTitle h3 {
  font-size: 18px;
  color: #1b1b1b;
  text-transform: uppercase;
  font-family: "Lato-Bold";
}

.authTitle {
  padding: 20px 0 15px 0;
  text-align: center;
}

.authInputWrap {
  position: relative;
  padding: 12px 0;
}

.custom-alert {
  /* font-size: 12px;
  margin-left: 5%;
  color: #be0000;
  max-width: 76%;
  position: fixed; */
}

.custom-alert {
  position: absolute;
  left: 15%;
  font-size: 13px;
  line-height: 1.3;
  color: #dc3545;
}

.custom-alert-success {
  font-size: 16px !important;
  color: green !important;
}

mo .checkWrap + .custom-alert {
  left: 23%;
  bottom: -10px;
}

.authInputWrap > input {
  border: 1px solid #530394;
  border-radius: 25px;
  /* margin: 0 auto; */
  font-size: 12px;
  box-shadow: none;
  outline: none;
  padding: 18px 12px;
  width: 76%;
  margin: 0 auto;
  display: block;
}

.authInputWrap > input:focus {
  box-shadow: none;
  outline: none;
  border-color: #530394;
}

.authSubmitWrap {
  text-align: center;
  padding: 15px 0 15px 0;
}

.authSubmitWrap > .btn {
  color: #fff;
  background-color: #e4c56a;
  border-radius: 25px;
  padding: 8px 40px;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Lato-Regular";
  transition: all 0.5s ease;
}

.authSubmitWrap > .btn:hover {
  background-color: #e8b10e;
  color: #fff;
}

.authSubmitWrap > .btn:hover,
.authSubmitWrap > .btn:focus,
.authSubmitWrap > .btn:active {
  outline: none;
  box-shadow: none;
}

.authSubActionWrap > p {
  font-size: 12px;
  color: #1b1b1b;
  margin: 0 0;
  line-height: 1.1;
  padding: 6px 0;
  font-family: "Lato-Bold";
}

.authSubActionWrap {
  text-align: center;
}

.authSubActionWrap > p > a {
  color: #530394;
}

.authCheckWrap {
  text-align: center;
}

/* Hide the browser's default checkbox */

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #fff;
  border: 1px solid #530394;
}

/* On mouse-over, add a grey background color */

/* .container:hover input ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */

input:checked ~ .checkmark {
  background-color: #fff;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.checkmark:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid #530394;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.authCheckWrap {
  display: flex;
  justify-content: center;
  position: relative;
}

.checkWrap {
  position: relative;
  padding: 0 0 0 24px;
}

.checkWrap > label {
  color: #1b1b1b;
  font-size: 13px;
  line-height: 1;
  font-family: "Lato-Regular";
}

.authSubmitWrap > .btn.theme-blue {
  background-color: #530394;
}

/*
  plan css
*/

.carousal-plan-wrap {
  background-color: #fff;
  border-radius: 8px;
  margin: 0 10px;
  position: relative;
  overflow: hidden;
}

.plan-red-top {
  background-color: #940335;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 24px;
  z-index: 1;
  text-align: center;
  font-size: 11px;
  font-family: "Lato-Bold";
  color: #fff;
  line-height: 23px;
}

/* .plan-red .carousal-plan-description-wrap {
  padding-bottom: 24px;
}
.carousal-plan-wrap.plan-red .carousal-plan-desc.text-2 {
  height: 86px;
} */

.plan-red-bottom {
  position: absolute;
  background-color: #940335;
  bottom: 0;
  left: 0;
  right: 0;
  height: 24px;
}

.carousal-plan-pic {
  position: relative;
  height: 200px;
}
.closeModal > img {
  width: 38px;
}
.carousal-plan-pic > img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.carousal-plan-name {
  padding: 10px 0;
  text-align: center;
}

.carousal-plan-name h3 {
  font-size: 20px;
  color: #530394;
  font-family: "Lato-Bold";
  margin: 0;
}

.carousal-plan-price {
  padding: 6px 0;
  text-align: center;
}

.carousal-plan-price > p {
  font-size: 45px;
  font-family: "Lato-Bold";
  margin: 0;
  line-height: 1;
}

.carousal-plan-desc {
  text-align: center;
  padding: 6px 6%;
  height: 110px;
  display: flex;
  align-items: center;
}

.carousal-item {
  width: 100%;
}

.carousal-plan-desc > p {
  font-size: 18px;
  color: #1b1b1b;
  font-family: "Lato-Regular";
  line-height: 1.5;
  margin: 0;
}

.carousal-plan-desc.text-1 > p {
  font-size: 14px;
}

.carousal-plan-desc.text-4 > p {
  font-size: 14px;
}

.carousal-plan-desc.text-5 > p {
  font-size: 14px;
}

.carousal-plan-action {
  padding: 6px 0 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.carousal-plan-action > .btn {
  background-color: #ccc;
  border-radius: 25px;
  color: #fff;
  text-transform: uppercase;
  font-family: "Lato-Bold";
  min-width: 200px;
  margin: 6px 0;
}

.appContentWrap {
  position: relative;
  z-index: 1;
}

.rec-arrow {
  display: none;
}

.rec-pagination {
  position: relative;
  /* top: -20px; */
  margin: 0 0;
}

.rec.rec-pagination {
  margin: 15px 0 0 0;
}

.carousal-plan-action > .btn:hover,
.carousal-plan-action > .btn:focus,
.carousal-plan-action > .btn:active {
  color: #fff;
  outline: none;
  box-shadow: none;
}

.btn.theme-b {
  background-color: #530394;
}

.btn.theme-y {
  background-color: #e4c56a;
}

.btn.theme-y:hover {
  background-color: #e8b10e;
}

.carousal-action-btn {
  position: absolute;
  top: -26px;
  color: #fff;
  font-family: "Lato-Bold";
  border-top: 2px solid #fff;
  font-size: 14px;
  border-bottom: 2px solid #fff;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.carousal-action-btn.left {
  left: 22px;
}

.carousal-action-btn.right {
  right: 22px;
}

.carousal-action-btn:hover {
  /* border-color: #530394;
  color: #530394; */
}

.carousal-action-wrap {
  position: relative;
  margin: 0 0 9px 0;
}

.rec-dot {
  border: 1px solid #fff !important;
  box-shadow: none !important;
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
}

.rec-dot.rec-dot_active {
  background-color: #fff !important;
}

.rec-dot:hover,
.rec-dot:focus {
  box-shadow: none !important;
}

.main-header {
  display: flex;
  align-items: center;
  padding: 10px 0 20px 0;
}

.main-logo-wrap {
  flex: 0 0 110px;
}

.main-logo-wrap > a > img {
  width: 100%;
}

.main-header-content {
  flex: 1;
  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.main-header-content .main-title {
  font-size: 21px;
  font-family: "Lato-Bold";
}

.main-header-content .contact-info {
  font-size: 12px;
  color: #fff;
  border: 1px solid #fff;
  padding: 2px 10px;
  margin: 6px 0 0 0;
}

.main-header-nav > a {
  display: inline-block;
  padding: 0 6px;
}

.main-header-nav > a > img {
  width: 16px;
}

.header-nav-btn.menuBtn > span {
  color: #fff;
  font-family: "Lato-Bold";
  display: inline-flex;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  line-height: 1;
  padding: 3px 0;
  transition: all 0.3s ease-in;
}

.header-nav-btn.menuBtn:hover {
  text-decoration: none !important;
}

.header-nav-btn.menuBtn:hover > span {
  color: #530394;
  border-color: #530394;
}

.isd-code {
  display: inline-block;
  padding-right: 3px;
  color: #e4c56a;
}

.benefit-modal {
  max-width: 100% !important;
  width: 740px !important;
}

.benefit-modal-inner {
  background-color: #2c2c2c;
  padding: 20px 25px;
}

.benefit-modal-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.closeModal {
  cursor: pointer;
  display: inline-block;
}

.benefit-modal-gallery {
  margin: 30px 0 30px 0;
  display: flex;
  align-items: center;
}

.benefit-modal-gallery > img:not(:last-child) {
  margin-right: 20px;
}

.benefit-modal-gallery > img {
  width: calc(25% - 15px);
}

.benefit-modal-title h3 {
  margin: 0;
  color: #e4c56a;
  font-size: 22px;
  font-family: "Lato-Bold";
}

.benefit-modal-title {
  text-align: center;
  margin: 0 0 16px 0;
}

.benefit-modal-topics > p {
  position: relative;
  margin: 0 0 16px 0;
  color: #fff;
  padding-left: 30px;
  font-size: 14px;
}

.benefit-modal-topics > p:before {
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  background-image: url("./assets/images/check-ico.png");
  width: 20px;
  height: 20px;
  background-size: cover;
}

.benefit-modal-topics {
  width: 58%;
  margin: 0 auto;
}

.benefit-modal-action > .btn {
  color: #fff;
  border-radius: 25px;
  min-width: 178px;
  text-transform: uppercase;
  font-family: "Lato-Bold";
  font-size: 14px;
  padding: 6px 0;
}

.benefit-modal-action > .btn:hover {
  color: #fff;
}

.benefit-modal-action {
  margin: 26px 0 20px 0;
}

.plan-detail-modal {
  width: 100% !important;
  max-width: 821px !important;
}

.plan-detail-modal-header {
  position: relative;
}

.plan-detail-title {
  text-align: center;
}

.plan-detail-title > h3 {
  color: #fff;
  font-size: 20px;
  text-transform: uppercase;
}

.plan-detail-modal-header .closeModal {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

/*
Plan Carousal CSS
*/

.plan-carousal-item {
  margin: 0 15px;
  position: relative;
}

.plan-carousal-actress-pic-wrap {
  position: relative;
  height: 250px;
}

.plan-carousal-actress-pic {
  height: 100%;
  position: relative;
}

.plan-carousal-actress-pic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.plan-carousal-actress-pic:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/images/gradient.png");
  height: 32px;
}

.plan-carousal-actress-basic-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 3px 5px;
  display: flex;
  align-items: center;
}

.actress-name {
  font-size: 11px;
  font-family: "Lato-Bold";
  color: #fff;
  text-transform: uppercase;
  padding-right: 4px;
}

.actress-live-status {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #25ce4c;
  border-radius: 50%;
  top: -1px;
  position: relative;
  margin-right: 3px;
}

.actress-username {
  font-size: 9px;
  color: #e4c56a;
  font-weight: 500;
}

.actress-more-action-wrap > span {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 1px;
}

.actress-basic-info-left {
  flex: 1;
}

.actress-basic-info-right {
  flex: 0 0 auto;
  cursor: pointer;
}

.plan-carousal-actress-pic-social-action {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: flex;
  flex-direction: column;
}

.actress-social-share-btn {
  margin-bottom: 6px;
}

.actress-social-share-btn > span {
  display: block;
  text-align: center;
  line-height: 1;
  font-size: 8px;
  color: #530394;
  font-family: "Lato-Bold";
}

.plan-carousal-actress-opt-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 4px 3px 24px 3px;
  background-color: #ffffff;
}

.plan-carousal-actress-opt-wrap > span {
  flex: 0 0 calc(33.33% - 4px);
  font-size: 8px;
  padding: 7px 1px;
  border: 1px solid #530394;
  margin: 3px 2px;
  text-align: center;
  font-family: "Lato-Bold";
  color: #530394;
  transition: all 0.3s ease-in;
  cursor: pointer;
}

.plan-carousal-item.plan-red .plan-carousal-actress-subscription .btn {
  background-color: #940335;
}

.plan-carousal-actress-opt-wrap > span:hover,
.plan-carousal-actress-opt-wrap > span.active {
  color: #ffffff;
  background-color: #530394;
}

.plan-carousal-actress-subscription {
  position: relative;
  bottom: 16px;
}

.plan-carousal-actress-subscription > .btn {
  font-size: 10px;
  font-family: "Lato-Bold";
  color: #fff;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 0;
  margin: 0 auto;
  width: 95%;
  display: inherit;
}

.plan-carousal-actress-subscription > .btn:hover {
  color: #ffffff;
}

.benefit-modal-gallery .rec-carousel {
  height: 385px !important;
}

.benefit-modal-gallery .rec.rec-pagination {
  display: none;
}

.plan-carousal-nav {
  position: absolute;
  transform: rotate(90deg);
  color: #fff;
  font-size: 10px;
  font-family: "Lato-Bold";
  cursor: pointer;
}

.plan-carousal-nav.left {
  left: 20px;
  transform: rotate(-90deg);
}

.plan-carousal-nav.right {
  right: 20px;
}

.plan-carousal-nav:hover {
  color: #e4c56a;
}

.plan-info-title h3 {
  font-size: 16px;
  font-family: "Lato-Bold";
  color: #e4c56a;
}

.plan-info-title {
  text-align: center;
}

.plan-info-option {
  display: flex;
  flex-wrap: wrap;
}

.plan-info-option > span {
  flex: 0 0 20%;
  margin: 5px 0;
  font-size: 14px;
  color: #fff;
}

.payment-title h3 {
  font-size: 22px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  margin: 0;
}

.payment-title {
  padding: 10px 0 26px 0;
}

.payment-form-col-wrap {
  display: flex;
}

.payment-form-col {
  flex: 1;
  padding: 0 10px;
}

.payment-form-col-title > p {
  color: #fff;
  margin: 0 0 0 0;
  line-height: 1;
  font-size: 16px;
  text-transform: uppercase;
}

.payment-form-col-title > span {
  color: #fff;
  line-height: 1;
  font-size: 8px;
}

.payment-form-col-title {
  margin: 0;
  min-height: 40px;
}

.payment-input-wrap input.form-control,
.payment-input-wrap select.form-control {
  border: 1px solid #e4c56a;
  background-color: transparent;
  border-radius: 25px;
  color: #888888;
  font-size: 14px;
}

.payment-input-wrap {
  position: relative;
  padding: 10px 62px 10px 0;
}

.payment-input-wrap.cardinfo,
.card-exp-date-input {
  display: flex;
}

.payment-input-wrap.cardinfo input:not(:last-child) {
  margin: 0 10px 0 0;
}

.payment-input-wrap.cardinfo {
  flex-direction: column;
}

.card-exp-date-error > span {
  position: relative;
  display: block;
}

.card-exp-date-error {
  position: relative;
}

.payment-input-wrap > label {
  font-size: 12px;
  color: #fff;
  line-height: 1.3;
}

.payment-input-wrap input[type="radio"] {
  position: relative;
  top: 3px;
}

.payment-opt-wrap > label > input[type="radio"] {
  position: relative;
  top: 2px;
}

.payment-opt-wrap > label {
  font-size: 14px;
  color: #fff;
  font-family: "Lato-Regular";
  font-weight: 500;
  margin: 0 0 12px 0;
}

.payment-opt-wrap {
  display: flex;
  flex-direction: column;
  max-width: 210px;
  margin: 32px auto;
}

.more-benefit-col-wrap {
  padding: 26px 0;
  position: relative;
  display: flex;
}

.more-benefit-col-left {
  flex: 0 0 40%;
}

.more-benefit-col-right {
  flex: 0 0 60%;
}

.more-benefit-gallery-wrap {
  display: flex;
  align-items: center;
}

.more-benefit-gallery-wrap > img {
  width: calc(50% - 24px);
  margin: 0 12px;
}

.more-benefit-title {
  text-align: center;
  margin: 0 0 10px 0;
}

.more-benefit-title > h3 {
  font-size: 16px;
  color: #fff;
  font-family: "Lato-Bold";
}

.more-benefit-gallery {
  display: flex;
}

.more-benefit-gallery-item {
  flex: 0 0 15.3%;
  margin: 0 1%;
  padding: 16px 0;
}

.more-benefit-gallery-item-txt {
  font-size: 10px;
  text-align: center;
  line-height: 1;
  color: #fff;
  padding: 6px 0 6px 0;
}

.more-benefit-gallery-item-pic {
  height: 44px;
  text-align: center;
}

.more-benefit-gallery-item-pic > img {
  width: 32px;
}

.more-benefit-labels {
  text-align: center;
}

.more-benefit-labels > span {
  display: block;
  color: #e4c56a;
  font-family: "Lato-Bold";
  letter-spacing: 0.5px;
  margin: 3px 0;
  font-size: 13px;
}

.profilePageWrap {
  background-color: #fff;
  padding: 20px 50px;
  border-radius: 10px;
  position: relative;
}

.profilePageTitleWrap {
  text-align: center;
  padding: 15px 0;
}

.profilePageTitleWrap > h3 {
  font-size: 24px;
  color: #530394;
}

.profileSectionTitle > h3 {
  font-size: 22px;
  color: #530394;
}

.profileInfoColWrap {
  display: flex;
  flex-wrap: wrap;
}

.profileInfoCol {
  flex: 0 0 auto;
}

.profileInfoCol.left {
  width: 60%;
}

.profileInfoCol.right {
  width: 40%;
}

.profileSectionTitle {
  margin: 0 0 20px 0;
}

.profileBasicInfoWrap {
  display: flex;
  align-items: center;
  position: relative;
  margin: 45px 0;
}

.profilePicWrap {
  flex: 0 0 auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #530394;
  position: relative;
}

.profilePicActionWrap {
  position: absolute;
  z-index: 1;
  background-color: rgba(83, 3, 148, 0.67);
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
}

.profilePicActionWrap > label {
  color: #fff;
  font-family: "Lato-Bold";
  cursor: pointer;
  margin: 0;
  font-size: 12px;
  line-height: 1;
}

input#changePic {
  left: -99999px;
  position: absolute;
  opacity: 0;
}

.profileNameWrap {
  display: flex;
  flex: 1;
  padding: 0 10px;
  flex-direction: column;
}

.userFullName {
  color: #530394;
  font-family: "Lato-Bold";
  line-height: 1;
  margin: 0 0 4px 0;
}

.userLocation {
  color: #e4c56a;
  font-family: "Lato-BOld";
  font-size: 12px;
}

.profileMoreInfoWrap {
  margin: 0 0 20px 0;
  position: relative;
}

.profileMoreInfoCol {
  display: flex;
  align-items: center;
  padding: 10px 0;
  position: relative;
}

.profileMoreInfoCol .profileMoreInfoVal {
  flex: 1;
  font-size: 12px;
  color: #2c2c2c;
  font-family: "Lato-bold";
}

.profileMoreInfoAction {
  color: #1b65ba;
  font-family: "Lato-Bold";
  font-size: 12px;
  cursor: pointer;
}

.profilePicWrap > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.profileInfoCol.right .profileSectionTitle {
  text-align: center;
}

.membershipDetailsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.membershipDetailsContainer {
  position: relative;
  width: 220px;
  margin: 0 auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.21);
  overflow: hidden;
  border-radius: 10px;
  min-height: 360px;
}

.membershipDetailsWrap {
  position: relative;
}

.membershipDetailsPic {
  height: 120px;
  position: relative;
}

.membershipDetailsPic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.membershipDetailsPic {
  height: 150px;
  position: relative;
}

.membershipDetailsInfoWrap {
  position: relative;
  padding: 5px;
}

.membershipTitle {
  text-align: center;
  font-size: 14px;
  color: #530394;
  font-family: "Lato-Bold";
  margin: 2px 0 2px 0;
}

.membershipPrice {
  text-align: center;
  margin: 2px 0;
  font-size: 30px;
  font-family: "Lato-Bold";
  color: #1b1b1b;
  line-height: 1;
}

.membershipDescription {
  line-height: 1.3;
  text-align: center;
  margin: 10px 0;
  min-height: 62px;
  color: #1b1b1b;
  font-size: 11px;
  font-family: "Lato-Bold";
  padding: 0 10px;
}

.membershipDetailsAction > .btn {
  font-size: 10px;
  font-family: "Lato-Bold";
  color: #fff;
  padding: 4px 15px;
  border-radius: 25px;
}

.membershipDetailsAction > .btn:hover,
.membershipDetailsAction > .btn:focus,
.membershipDetailsAction > .btn:active {
  box-shadow: none;
  outline: none;
  color: #ffffff;
}

.membershipDetailsAction {
  margin: 0 0 21px 0;
}

.profilePageWrap.forChangeEmail {
  width: 455px;
  margin: 0 auto;
  max-width: 100%;
}

.form-group.forinput {
  position: relative;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
}

.form-group.forcheck {
  position: relative;
  padding: 0 0 0 20px;
}

.form-group.forinput {
  position: relative;
  max-width: 220px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 0 15px 0;
  margin: 0 auto 8px auto;
}

.forgetPasswordNavWrap {
  position: relative;
  font-size: 10px;
  padding: 0 0 0 6px;
  color: #0088d0;
  font-family: "Lato-Bold";
  cursor: pointer;
}

.form-group.forcheck label {
  font-size: 12px;
  color: #1b1b1b;
}

.form-group.btn-wrap.form-btns {
  text-align: center;
}

.form-btns > .btn {
  color: #fff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 25px;
  padding: 7px 20px;
  min-width: 110px;
  font-family: "Lato-Bold";
}

.form-btns > .btn:hover,
.form-btns > .btn:focus,
.form-btns > .btn:active {
  color: #ffffff;
  outline: none;
  box-shadow: none;
}

.form-group.btn-wrap.form-btns > .btn:first-child {
  margin-right: 30px;
}

.form-group.forinput input.form-control {
  border: 1px solid #530394;
  border-radius: 25px;
  font-size: 12px;
  box-shadow: none;
  outline: none;
  padding: 18px 12px;
  display: block;
}

.btn {
  font-family: "Lato-Bold" !important;
}

.plan-carousal-item.plan-red .plan-carousal-actress-opt-wrap > span {
  color: #940335;
  border-color: #940335;
}

.plan-carousal-item.plan-red .plan-carousal-actress-opt-wrap > span:hover {
  background-color: #940335;
  color: #ffffff;
}

.plan-carousal-item.plan-red .plan-carousal-actress-pic-label {
  background-color: #940335;
  position: absolute;
  z-index: 1;
  font-size: 9px;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  font-family: "Lato-Bold";
  color: #ffffff;
  height: 21px;
  line-height: 20px;
}

.action-result-msg {
  height: 54vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.modal-dialog.plan-detail-modal.plan1080 {
  max-width: 1080px !important;
}
.noSubsBlock {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bfbfbf;
  background-image: url("./assets/images/add-icon.png");
  background-position: center center;
  background-repeat: no-repeat;
  cursor: pointer;
}
.noSubsBlock .txt {
  font-size: 14px;
  color: #6a6a6a;
  font-family: "Lato-Bold";
  position: absolute;
  top: 58%;
  left: 16%;
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .authwrapper.prelogin {
    overflow: auto;
  }
  .auth-container {
    height: auto;
    max-width: 100vw;
    padding-bottom: 20px;
  }
  .authHeader > a.authlogo > img {
    width: 62px;
  }
  .auth-collage {
    position: relative;
    height: 100%;
    min-height: 258px;
    max-width: 294px;
    left: 50%;
    transform: translateX(-50%);
    top: -15px;
  }
  .auth-col.left {
    width: 100%;
  }
  .auth-col.right {
    width: 100%;
  }
  .collage-1 {
    z-index: 6;
    width: 36%;
  }
  .collage-2 {
    width: 34%;
    z-index: 5;
  }
  .collage-3 {
    width: 28%;
    z-index: 4;
  }
  .collage-4 {
    width: 32%;
    z-index: 3;
  }
  .collage-5 {
    width: 34%;
    z-index: 2;
  }
  .collage-6 {
    width: 32%;
    z-index: 1;
  }
  .authLoginBoxWrap.loginbox {
    max-width: 320px;
    margin: 10px auto;
  }
  .authLoginBoxWrap {
    padding: 20px 20px 38px 20px;
  }
  .authTitle {
    padding: 6px 0 6px 0;
    text-align: center;
  }
  .authInputWrap > input {
    max-width: 90%;
  }
  .authwrapper {
    position: static;
  }
  .main-logo-wrap {
    flex: 0 0 60px;
  }
  .main-header-content .main-title {
    font-size: 14px;
    text-align: center;
  }
  .main-header-nav > a > img {
    width: 12px;
  }
  .main-header-nav > a {
    padding: 0 3px;
  }
  .header-nav-btn.menuBtn > span {
    font-size: 10px;
  }
  .carousal-plan-name {
    padding: 8px 0;
  }
  .carousal-plan-desc > p {
    font-size: 16px;
  }
  .payment-form-col-wrap {
    flex-direction: column;
  }
  .payment-input-wrap {
    padding: 10px;
  }
  .payment-form-col-title {
    min-height: auto;
    margin: 8px 0 8px 0;
  }
  .plan-info-option > span {
    flex: 0 0 calc(50% - 10px);
    margin: 5px 5px;
  }
  .more-benefit-col-wrap {
    flex-direction: column;
  }
  .more-benefit-col-left {
    flex: 1;
  }
  .more-benefit-gallery-item {
    flex: 0 0 26.3%;
    margin: 0 1%;
    padding: 16px 0;
  }
  .more-benefit-gallery {
    overflow: auto;
  }
  /* .benefit-modal-inner {
    padding: 20px 10px;
  } */
  .more-benefit-col-right {
    flex: 1;
    margin: 20px 0 0 0;
  }
  .carousal-plan-price > p {
    font-size: 32px;
  }
  .carousal-plan-name h3 {
    font-size: 16px;
  }
  .benefit-modal-topics {
    width: 92%;
  }
  .rec.rec-pagination {
    margin: 16px 0 0 0;
  }
  .profileInfoColWrap {
    flex-direction: column;
  }
  .profileInfoCol.right,
  .profileInfoCol.left {
    width: 100%;
  }
  .profilePageWrap {
    padding: 20px 20px;
  }
  .form-group.forinput {
    max-width: 100%;
  }
  .profileSectionTitle > h3 {
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (min-width: 767px) {
  .collage-1 {
    animation-name: rotate1;
  }
  .collage-2 {
    animation-name: rotate2;
  }
  .collage-3 {
    animation-name: rotate3;
  }
  .collage-4 {
    animation-name: rotate4;
  }
  .collage-5 {
    animation-name: rotate5;
  }
  .collage-6 {
    animation-name: rotate6;
  }
}
